<style>
.vdp-datepicker {
  padding: 0;
}

control:disabled, .form-control[readonly] {
  background-color: white;
  border-bottom: 1px solid #e4e7ea;
}

.modal-footer {
  display: none;
}

.ps-r20 {
  padding-right: 20px
}

.carrier_details, .carrier_details_transport, .carrier_option {
  display: none;
}

</style>
<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power"/>
      {{ $t('forms.transl191')}}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
<!--            <ul class="nav nav-tabs nav-justified">-->
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" @click.prevent="setActive('tab1')" :class="{ active: isActive('tab1') }"-->
<!--                   href="#tab1">Ogólne</a>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" @click.prevent="setActive('tab2')" :class="{ active: isActive('tab2') }"-->
<!--                   href="#tab2">Historia-->
<!--                  zmian</a>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }"-->
<!--                   href="#tab3">Pliki</a>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" @click.prevent="setActive('tab4')" :class="{ active: isActive('tab4') }"-->
<!--                   href="#tab4">Dostawa</a>-->
<!--              </li>-->
<!--            </ul>-->
            <div class="tab-content py-3" id="myTabContent">
              <div class="tab-pane fade" :class="{ 'active show': isActive('tab1') }" id="tab1">

                <h4 class="py-3">{{ $t('forms.transl193')}}</h4>
                <CRow>
                  <CCol md="12">
                    <CSelect id="dispatcher_id"
                             :label="$i18n.translate('forms.transl194')"
                             v-model.trim="$v.form.dispatcher_id.$model"
                             :options="dispatcherArray"
                    >
                    </CSelect>
                  </CCol>
<!--                  <CCol md="12">-->
<!--                    <CInput-->
<!--                      :label="Nazwa dyspozytora"-->
<!--                      v-model.trim="$v.form.dispatcher_name.$model"-->
<!--                      plaintext-->
<!--                    />-->
<!--                  </CCol>-->
                </CRow>

                <h4 class="py-3">{{ $t('forms.transl195')}} </h4>
                <CRow>
                  <CCol md="6">
                    <CInput
                      maxlength="255"
                      :label="$i18n.translate('forms.transl196')"
                      v-model.trim="$v.form.speed_number.$model"
                      :isValid="checkIfValid('speed_number')"
                      :placeholder="$i18n.translate('forms.transl196')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                  <CCol md="6">
                    <CInput
                      maxlength="255"
                      :label="$i18n.translate('forms.transl197')"
                      v-model.trim="$v.form.order_number.$model"
                      :placeholder="$i18n.translate('forms.transl197')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6" class="pb-3">
                    <label for="data_start">{{ $t('forms.transl198')}}</label>
                    <datepicker
                      :label="$i18n.translate('forms.transl199')"
                      v-model.trim="$v.form.data_start.$model"
                      :language="pl"
                      format="yyyy-MM-dd"
                      class="form-control"
                      :placeholder="$i18n.translate('forms.transl199')"
                      wrapper-class=""
                      input-class="form-control"
                      monday-first
                    ></datepicker>
                  </CCol>
                  <CCol md="6">
                    <CInput
                      maxlength="255"
                      :label="$i18n.translate('forms.transl200')"
                      v-model.trim="$v.form.hour_from.$model"
                      :isValid="checkIfValid('hour_from')"
                      :placeholder="$i18n.translate('forms.transl200')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                </CRow>


                <CRow>
                  <CCol md="6" class="pb-3">
                    <label for="data_finish">{{ $t('forms.transl201')}}</label>
                    <datepicker
                      :label="$i18n.translate('forms.transl202')"
                      v-model.trim="$v.form.data_finish.$model"
                      :language="pl"
                      format="yyyy-MM-dd"
                      class="form-control"
                      :placeholder="$i18n.translate('forms.transl202')"
                      wrapper-class=""
                      input-class="form-control"
                      monday-first
                    ></datepicker>
                  </CCol>
                  <CCol md="6">
                    <CInput
                      maxlength="255"
                      :label="$i18n.translate('forms.transl203')"
                      v-model.trim="$v.form.hour_to.$model"
                      :placeholder="$i18n.translate('forms.transl203')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                </CRow>


                <CRow>
                  <CCol md="6">
                    <CInput
                      :label="$i18n.translate('forms.transl204')"
                      v-model.trim="$v.form.address_from.$model"
                      :isValid="checkIfValid('address_from')"
                      :placeholder="$i18n.translate('forms.transl204')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                  <CCol md="6">
                    <CInput
                      :label="$i18n.translate('forms.transl205')"
                      v-model.trim="$v.form.address_to.$model"
                      :isValid="checkIfValid('address_to')"
                      :placeholder="$i18n.translate('forms.transl205')"
                      :invalidFeedback="$i18n.translate('forms.transl30')"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6">
                    <CSelect id="is_neutral_option"
                             :label="$i18n.translate('forms.transl206')"
                             v-model.trim="$v.form.is_neutral_option.$model"
                             :options="confirmationTypes"
                    >
                    </CSelect>
                  </CCol>
                  <CCol md="6">
                    <CSelect id="transport_type"
                             @change="onChangeTransportType($event)"
                             v-model="key_transportType"
                             :label="$i18n.translate('forms.transl207')"
                             v-model.trim="$v.form.transport_type.$model"
                             :options="transportTypes"
                    >
                    </CSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <CSelect id="transport_status"
                             :label="$i18n.translate('forms.transl208')"
                             v-model.trim="$v.form.transport_status.$model"
                             :options="transportStatuses"
                    >
                    </CSelect>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol md="12">
                    <CSelect id="hide_data_in_print"
                             :label="$i18n.translate('forms.transl209')"
                             v-model.trim="$v.form.hide_data_in_print.$model"
                             :options="printStatuses"
                    >
                    </CSelect>
                  </CCol>
                </CRow>

                <div class="" :class="{carrier_details_transport: isSpedytor}">
                  <CRow>
                    <CCol md="6">
                      <CSelect id="our_car"
                               :label="$i18n.translate('forms.transl210')"
                               v-model.trim="$v.form.our_car.$model"
                               :options="ourCarsArray"
                      >
                      </CSelect>
                    </CCol>
                    <CCol md="6">
                      <CSelect id="our_drivers"
                               :label="$i18n.translate('forms.transl211')"
                               v-model.trim="$v.form.our_drivers.$model"
                               :options="ourDriversArray"
                      >
                      </CSelect>
                    </CCol>
                  </CRow>
                </div>

                <div class="" :class="{carrier_details: isTransport}">
                  <h4 class="py-3 d-inline-block pe-5 me-5 ps-r20">{{ $t('forms.transl212')}} </h4>
                  <!--                <a href="" role="button" class="btn btn-primary ms-5" @click="toggle()">Uzupełnij</a>-->
                  <CButton
                    @click="warningModal = true"
                    color="primary"
                  >
                    {{ $t('forms.transl213')}}
                  </CButton>
                  <CInput
                    v-model.trim="$v.form.carrier_id.$model"
                    :isValid="checkIfValid('carrier_id')"
                    type="hidden"
                  />
                  <CRow>
                    <CCol md="12">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl214')"
                        v-model.trim="$v.form.carrier_name.$model"
                        :isValid="checkIfValid('carrier_name')"
                        :placeholder="$i18n.translate('forms.transl214')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl215')"
                        v-model.trim="$v.form.carrier_nip.$model"
                        :isValid="checkIfValid('carrier_nip')"
                        :placeholder="$i18n.translate('forms.transl215')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl216')"
                        v-model.trim="$v.form.carrier_email.$model"
                        :isValid="checkIfValid('carrier_email')"
                        :placeholder="$i18n.translate('forms.transl216')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl217')"
                        v-model.trim="$v.form.carrier_phone.$model"
                        :isValid="checkIfValid('carrier_phone')"
                        :placeholder="$i18n.translate('forms.transl217')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl218')"
                        v-model.trim="$v.form.carrier_postal_code.$model"
                        :isValid="checkIfValid('carrier_postal_code')"
                        :placeholder="$i18n.translate('forms.transl218')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl219')"
                        v-model.trim="$v.form.carrier_street.$model"
                        :isValid="checkIfValid('carrier_street')"
                        :placeholder="$i18n.translate('forms.transl219')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl220')"
                        v-model.trim="$v.form.carrier_city.$model"
                        :isValid="checkIfValid('carrier_city')"
                        :placeholder="$i18n.translate('forms.transl220')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="9">
                      <CSelect id="driver_id"
                               :label="$i18n.translate('forms.transl221')"
                               v-model.trim="$v.form.driver_id.$model"
                               :options="drivers"
                      >
                      </CSelect>
                    </CCol>
                    <CCol md="3">
                      <div class="" :class="{carrier_option: isNotCarrierSelected}">
                        <CButton
                          @click="addDriverModal = true"
                          color="primary"
                          class="mt-4"
                        >
                          {{ $t('forms.transl222')}}
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12">
                      <CSelect id="car_id"
                               :label="$i18n.translate('forms.transl184')"
                               v-model.trim="$v.form.car_id.$model"
                               :options="cars"
                      >
                      </CSelect>
                    </CCol>
                  </CRow>
                  <!--                  <CRow>-->
                  <!--                    <CCol md="12">-->
                  <!--                      <CInput-->
                  <!--                        maxlength="255"-->
                  <!--                        :label="Email do powiadomień*"-->
                  <!--                        v-model.trim="$v.form.carrier_email_to_notification.$model"-->
                  <!--                        :isValid="checkIfValid('carrier_email_to_notification')"-->
                  <!--                        :placeholder="Wpisz email do powiadomień"-->
                  <!--                        :invalidFeedback="$i18n.translate('forms.transl30')"-->
                  <!--                      />-->
                  <!--                    </CCol>-->
                  <!--                  </CRow>-->
                </div>


                <CRow>
                  <CCol md="12">
                    <CTextarea
                      :label="$i18n.translate('forms.transl224')"
                      :placeholder="$i18n.translate('forms.transl224')"
                      rows="9"
                      v-model.trim="$v.form.comments.$model"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <CTextarea
                      :label="$i18n.translate('forms.transl225')"
                      :placeholder="$i18n.translate('forms.transl225')"
                      rows="9"
                      v-model.trim="$v.form.transport_content.$model"
                    />
                  </CCol>
                </CRow>

                <h4 class="py-3">{{ $t('forms.transl226')}} </h4>
                <template v-for="(option) in productDircionary">
                  <div class="form-group form-row" :key="option.name">
                    <CCol sm="12">
                      <input type="checkbox" name="selectedProducts[]" :value="option.value" v-model="selectedProducts"
                             :id="option.value" :checked="selectedProducts.includes(option.value)"/> {{ option.label }}
                    </CCol>
                  </div>
                </template>


<!--                <div>wybrano: {{ selectedProducts }}</div>-->

                <h4 class="py-3">{{ $t('forms.transl227')}} </h4>

                <template v-for="(option) in documentDircionary">
                  <div class="form-group form-row" :key="option.name">
                    <CCol sm="12">
                      <input type="checkbox" name="selectedDocuments[]" :value="option.value"
                             v-model="selectedDocuments" :id="option.value"
                             :checked="selectedDocuments.includes(option.value)"/> {{ option.label }}
                    </CCol>
                  </div>
                </template>
<!--                <div>wybrano: {{ selectedDocuments }}</div>-->


                <h4 class="py-3">{{ $t('forms.transl228')}} </h4>
                <template v-for="(option) in fileDircionary">
                  <div class="form-group form-row" :key="option.name">
                    <CCol sm="12">
                      <input type="checkbox" name="selectedFiles[]" :value="option.value" v-model="selectedFiles"
                             :id="option.value" :checked="selectedFiles.includes(option.value)"/> {{ option.label }}
                    </CCol>
                  </div>
                </template>
<!--                <div>wybrano: {{ selectedFiles }}</div>-->

                <CButton
                  color="primary"
                  @click="submit"
                >
                  {{ $i18n.translate('forms.transl52') }}
                </CButton>
                <CButton
                  class="ml-1"
                  color="success"
                  @click="goBack"
                >
                  {{ $i18n.translate('forms.transl53') }}
                </CButton>
              </div>
              <div class="tab-pane fade" :class="{ 'active show': isActive('tab2') }" id="tab2">{{ $t('forms.transl229')}}</div>
              <div class="tab-pane fade" :class="{ 'active show': isActive('tab3') }" id="tab3">{{ $t('forms.transl237')}}</div>
              <div class="tab-pane fade" :class="{ 'active show': isActive('tab4') }" id="tab4">
                <CRow>
                  <CCol md="12">
                    <CTextarea
                      :label="$i18n.translate('forms.transl129')"
                      :placeholder="$i18n.translate('forms.transl129')"
                      rows="9"
                      v-model.trim="$v.form.deliver_comments.$model"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    {{ $t('forms.transl242')}}
                  </CCol>
                </CRow>
                <CButton
                  color="primary"
                  @click="submit"
                >
                  {{ $i18n.translate('forms.transl52') }}
                </CButton>
                <CButton
                  class="ml-1"
                  color="success"
                  @click="goBack"
                >
                  {{ $i18n.translate('forms.transl53') }}
                </CButton>
              </div>
            </div>
          </CForm>
          <br/>
          <div>
            <CModal
              :title="$i18n.translate('forms.transl243')"
              color="info"
              :show.sync="warningModal"
              size="xl"
              :closeOnBackdrop=true
              :centered="true"
            >
              <data-table-select
                :fetch-url="datatTableUrl"
                :columns="['id', 'email', 'name', 'surname']"
                :headers="{'id': 'ID','email': $t('forms.transl244'),'name': $t('forms.transl245'),'surname': $t('forms.transl246')}"
                :routeName="routeName"
                @content-selected="handleContentChanged($event)"
              ></data-table-select>
            </CModal>
          </div>


          <div>
            <CModal
              title="$i18n.translate('forms.transl247')"
              color="info"
              :show.sync="addDriverModal"
              size="xl"
              :closeOnBackdrop=true
              :centered="true"
            >
              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CForm>
                      <CRow>
                        <CCol md="6">
                          <CInput
                            maxlength="255"
                            :label="$i18n.translate('forms.transl248')"
                            v-model.trim="$v.form2.popup_name.$model"
                            :placeholder="$i18n.translate('forms.transl248')"
                            :invalidFeedback="$i18n.translate('forms.transl30')"
                          />
                        </CCol>
                        <CCol md="6">
                          <CInput
                            maxlength="255"
                            :label="$i18n.translate('forms.transl249')"
                            v-model.trim="$v.form2.popup_surname.$model"
                            :placeholder="$i18n.translate('forms.transl249')"
                            :invalidFeedback="$i18n.translate('forms.transl30')"
                          />
                        </CCol>
                      </CRow>

                      <CInput
                        maxlength="255"
                        :label="$i18n.translate('forms.transl250')"
                        v-model.trim="$v.form2.popup_phone.$model"
                        :placeholder="$i18n.translate('forms.transl250')"
                        :invalidFeedback="$i18n.translate('forms.transl30')"
                      />

                      <CButton
                        color="primary"
                        @click="submitAddDriver"
                      >
                        {{ $t('forms.transl251')}}
                      </CButton>
                    </CForm>
                  </CCol>
                </CRow>
              </CCardBody>
            </CModal>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"
import Datepicker from 'vuejs-datepicker';
import {pl} from 'vuejs-datepicker/dist/locale'
import DataTableSelect from "../../components/DataTableSelect";

export default {
  components: {
    DataTableSelect,
    Datepicker
  },
  data() {
    return {
      idCarrier: null,
      isTransport: false,
      isSpedytor: true,
      isNotCarrierSelected: true,
      key_transportType: "",
      warningModal: false,
      addDriverModal: false,
      datatTableUrl: '',
      routeName: 'carriers',
      modalClasses: ['modal', 'fade'],
      checkedNames: [],
      pl: pl,
      activeItem: 'home',

      form2: {
        popup_name: null,
        popup_surname: null,
        popup_phone: null,
      },
      form: {
        speed_number: null,
        order_number: null,
        data_start: null,
        hour_from: null,
        hour_to: null,
        data_finish: null,
        address_from: null,
        address_to: null,
        is_neutral_option: null,
        our_car: null,
        our_drivers: null,
        dispatcher_id: null,
        transport_type: null,
        transport_status: null,
        hide_data_in_print: null,
        carrier_id: null,
        dispatcher_name: null,
        carrier_name: null,
        carrier_nip: null,
        carrier_email: null,
        carrier_postal_code: null,
        carrier_street: null,
        carrier_city: null,
        // carrier_email_to_notification: null,
        driver_id: null,
        car_id: null,
        comments: null,
        transport_content: null,
        deliver_comments: null,
        carrier_phone: null,
      },
      confirmationTypes: [],
      transportTypes: [],
      transportStatuses: [],
      printStatuses: [],
      ourCarsArray: [],
      ourDriversArray: [],
      drivers: [],
      cars: [],
      productDircionary: [],
      documentDircionary: [],
      dispatcherArray: [],
      fileDircionary: [],
      selectedProducts: [],
      selectedDocuments: [],
      selectedFiles: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      speed_number: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      order_number: {
        minLength: minLength(3),
        maxLength: 255
      },
      data_start: {},
      hour_to: {},
      hour_from: {},
      data_finish: {},
      address_from: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      address_to: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      is_neutral_option: {},
      our_car: {},
      our_drivers: {},
      dispatcher_id: {},
      dispatcher_name: {},
      transport_type: {},
      transport_status: {},
      hide_data_in_print: {},
      carrier_id: {},
      carrier_name: {
        maxLength: 255
      },
      carrier_nip: {
        maxLength: 255
      },
      carrier_street: {
        maxLength: 255
      },
      carrier_email: {
        maxLength: 255
      },
      carrier_phone: {
        maxLength: 255
      },
      carrier_postal_code: {
        maxLength: 255
      },
      carrier_city: {
        maxLength: 255
      },
      // carrier_email_to_notification: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: 255
      // },
      driver_id: {},
      car_id: {},
      comments: {},
      transport_content: {},
      deliver_comments: {},
    },
    form2: {
      popup_name: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      popup_surname: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      popup_phone: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
    }
  },
  methods: {
    onChangeTransportType(event) {
      if (event.target.value == 1) {
        this.isTransport = true;
        this.isSpedytor = false;
        this.isNotCarrierSelected = true;
      } else {
        this.isTransport = false;
        this.isSpedytor = true;
        this.isNotCarrierSelected = true;
      }
    },
    handleContentChanged(content) {
      let self = this;
      // console.log(content.name);
      // console.log(content);

      //self.form.carrier_name = content.name;
      // self.form.carrier_name = content.name;

      self.form.carrier_id = content.id;
      self.form.carrier_name = content.company_name;
      self.form.carrier_nip = content.company_nip;
      self.form.carrier_email = content.company_email;
      self.form.carrier_postal_code = content.company_postal_code;
      self.form.carrier_street = content.company_street;
      self.form.carrier_city = content.company_city
      self.form.carrier_phone = content.company_phone

      this.getCarrierDrivers(content.id);
      this.getCarrierCars(content.id);

      self.idCarrier = content.id;

      this.warningModal = false;

    },
    getCarrierDrivers(id) {
      let self = this;
      self.drivers = [];
      this.isNotCarrierSelected = false;
      axios.get(this.$apiAdress + '/api/getCarrierDrivers?token=' + localStorage.getItem("api_token") + '&id=' + id)
        .then(function (response) {
          self.drivers = response.data.drivers;
        }).catch(function (error) {
        console.log(error);
      });
    },
    getCarrierCars(id) {
      let self = this;
      self.cars = [];
      axios.get(this.$apiAdress + '/api/getCarrierCars?token=' + localStorage.getItem("api_token") + '&id=' + id)
        .then(function (response) {
          self.cars = response.data.cars;
        }).catch(function (error) {
        console.log(error);
      });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    goBack() {
      this.$router.replace({path: '/tasks'})
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store2() {
      let self = this;
      axios.post(this.$apiAdress + '/api/carrier-drivers/store/' + this.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          userId: self.idCarrier,
          name: self.form2.popup_name,
          surname: self.form2.popup_surname,
          phone: self.form2.popup_phone,
          status: 1,
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
              self.$i18n.translate('forms.transl63'),
              'success'
            ).then(function () {
              self.getCarrierDrivers(self.idCarrier);
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },
    store() {
      let self = this;

      axios.post(this.$apiAdress + '/api/tasks?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          speed_number: self.form.speed_number,
          order_number: self.form.order_number,
          data_start: self.form.data_start,
          hour_to: self.form.hour_to,
          hour_from: self.form.hour_from,
          data_finish: self.form.data_finish,
          address_from: self.form.address_from,
          address_to: self.form.address_to,
          is_neutral_option: self.form.is_neutral_option,
          our_drivers: self.form.our_drivers,
          our_car: self.form.our_car,
          dispatcher_id: self.form.dispatcher_id,
          transport_type: self.form.transport_type,
          transport_status: self.form.transport_status,
          hide_data_in_print: self.form.hide_data_in_print,
          carrier_id: self.form.carrier_id,
          carrier_name: self.form.carrier_name,
          carrier_nip: self.form.carrier_nip,
          carrier_email: self.form.carrier_email,
          carrier_postal_code: self.form.carrier_postal_code,
          carrier_street: self.form.carrier_street,
          carrier_city: self.form.carrier_city,
          // carrier_email_to_notification: self.form.carrier_email_to_notification,
          driver_id: self.form.driver_id,
          car_id: self.form.car_id,
          comments: self.form.comments,
          transport_content: self.form.transport_content,
          deliver_comments: self.form.deliver_comments,
          carrier_phone: self.form.carrier_phone,
          selected_products: self.selectedProducts,
          selected_documents: self.selectedDocuments,
          selected_files: self.selectedFiles,
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
              self.$i18n.translate('forms.transl63'),
              'success'
            ).then(function () {
              self.$router.push('/tasks');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    submit() {
      let self = this;

      Swal.fire({
        title: self.$i18n.translate('forms.transl252'),
        text: self.$i18n.translate('forms.transl253'),
        type: "question",
        showCancelButton: true,
        confirmButtonText: self.$i18n.translate('forms.transl254'),
        cancelButtonText: self.$i18n.translate('forms.transl255'),
        reverseButtons: true
      }).then(function (result) {
        if (result.value) {
          self.runSubmit();
        }
      });
    },
    submitAddDriver() {
      let self = this;

      Swal.fire({
        title: self.$i18n.translate('forms.transl256'),
        text: self.$i18n.translate('forms.transl257'),
        type: "question",
        showCancelButton: true,
        confirmButtonText: self.$i18n.translate('forms.transl254'),
        cancelButtonText: self.$i18n.translate('forms.transl255'),
        reverseButtons: true
      }).then(function (result) {
        if (result.value) {
          self.runSubmit2();
        }
      });
    },

    runSubmit() {
      let self = this;
      self.validate();
      if (!this.$v.form.$invalid) {
        this.store();
      }
    },

    runSubmit2() {
      let self = this;
      self.validate();
      if(this.$v.form2.popup_name.$invalid === true || this.$v.form2.popup_name === true || this.$v.form2.popup_surname === true){
        Swal.fire({
          title: self.$i18n.translate('forms.transl258'),
          text: self.$i18n.translate('forms.transl259'),
          type: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
          reverseButtons: true
        })
      } else {
        this.store2();
        this.addDriverModal = false;
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        speed_number: null,
        order_number: null,
        data_start: null,
        hour_from: null,
        hour_to: null,
        data_finish: null,
        address_from: null,
        address_to: null,
        is_neutral_option: null,
        our_car: null,
        our_drivers: null,
        dispatcher_id: null,
        transport_type: null,
        transport_status: null,
        hide_data_in_print: null,
        carrier_id: null,
        dispatcher_name: null,
        carrier_name: null,
        carrier_nip: null,
        carrier_email: null,
        carrier_postal_code: null,
        carrier_street: null,
        carrier_city: null,
        // carrier_email_to_notification: null,
        driver_id: null,
        car_id: null,
        comments: null,
        transport_content: null,
        deliver_comments: null,
        carrier_phone: null,
        selected_products: null,
        selectedProducts: [],
        selectedDocuments: [],
        selectedFiles: [],
      }
    }
  },
  created: function () {
    this.datatTableUrl = Vue.prototype.$apiAdress + '/api/tasks/carriers-table?token=' + localStorage.getItem("api_token");
    let self = this;
    self.setActive('tab1');
    axios.get(this.$apiAdress + '/api/tasks/create?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.confirmationTypes = response.data.confirmationTypes;
        // self.drivers = response.data.drivers;
        self.transportTypes = response.data.transportTypes;
        self.transportStatuses = response.data.transportStatuses;
        self.printStatuses = response.data.printStatuses;
        self.ourCarsArray = response.data.ourCarsArray;
        self.ourDriversArray = response.data.ourDriversArray;
        self.productDircionary = response.data.productDircionary;
        self.documentDircionary = response.data.documentDircionary;
        self.dispatcherArray = response.data.dispatcherArray;
        self.fileDircionary = response.data.fileDircionary;
        self.form.dispatcher_name = response.data.dispatcher_name;
        // self.selectedProducts = response.data.selectedProducts;
        // self.selectedDocuments = response.data.selectedDocuments;
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }

}
</script>

